export interface LocomotiveScrollElement {
  [key: string]: string | number | boolean;
}

export interface LocomotiveScrollConfig {
  id: string;
  call: string;
  speed: number;
  delay: number;
  repeat: boolean;
  offset: string;
  sticky: boolean;
  target: string;
  direction: 'vertical' | 'horizontal';
  position: 'top' | 'bottom' | 'left' | 'right';
  class: string;
}

export const useLocomotiveElementConfig = (
  config?: Partial<LocomotiveScrollConfig>,
  disable = false,
) => {
  if (disable) return {};
  const params: Partial<LocomotiveScrollElement> = {'data-scroll': true};
  if (config) {
    Object.entries(config)
      .filter(([, value]) => value !== undefined && value !== null)
      .forEach(([key, value]) => (params[`data-scroll-${key}`] = value));
  }
  return params;
};
