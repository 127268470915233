import {useCallback, useEffect, useRef} from 'react';

import {useRouter} from 'next/router';
import {useLocomotiveScroll} from 'react-locomotive-scroll';

import {ScrollProperties} from '@/components/LocomotiveScroll';
import debounce from '@/utils/debounce';

interface ScrollTracking {
  percentage: number;
  progress: number;
  length: number;
}

const useLocomotiveScrollDepth = (callback: (data: ScrollTracking) => void) => {
  const router = useRouter();
  const {scroll} = useLocomotiveScroll();
  const hasSubmittedRef = useRef(false);
  const dataRef = useRef<ScrollTracking>({
    percentage: 0,
    progress: 0,
    length: 0,
  });

  useEffect(() => {
    if (!scroll) return;

    scroll.on(
      'scroll',
      debounce((data: ScrollProperties) => {
        const percentage = Math.round((data.scroll.y / data.limit.y) * 100);

        if (dataRef.current.percentage < percentage) {
          dataRef.current = {
            percentage,
            progress: data.scroll.y,
            length: data.limit.y,
          };
        }
      }, 50),
    );
  }, [scroll, router.asPath]);

  const handleRouteChange = useCallback(() => {
    !hasSubmittedRef.current && callback(dataRef.current);
    hasSubmittedRef.current = true;
  }, [callback]);

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);

    return () => router.events.off('routeChangeStart', handleRouteChange);
  }, [router, handleRouteChange]);
};

export default useLocomotiveScrollDepth;
