import {CursorType} from '@/components/Cursor';
import {eventClickFooterCtaLink} from '@/config/tracking';
import {LinkWithHeading} from '@/content/cms/types';
import {useGlobals} from '@/contexts/globals';

import {
  FooterCtaWrapper,
  StyledHeading,
  LinkWrapper,
  StyledLink,
  StyledCopy,
} from './styles';

interface Props {
  cta: LinkWithHeading;
}

const FooterCta = ({cta}: Props) => {
  const {footer} = useGlobals();
  const titleOverflowsWrapper = cta.link.title.length > 8;
  return (
    <FooterCtaWrapper>
      <StyledHeading level={5}>{footer.discoverNextHeading}</StyledHeading>
      <LinkWrapper>
        <StyledLink
          link={cta.link}
          tracking={eventClickFooterCtaLink(cta.link.title)}
          cursor={CursorType.ExternalLink}
          $titleOverflowsWrapper={titleOverflowsWrapper}
        >
          {cta.link.title}
        </StyledLink>
        <StyledCopy
          serifSize="large"
          $titleOverflowsWrapper={titleOverflowsWrapper}
        >
          {cta.heading}
        </StyledCopy>
      </LinkWrapper>
    </FooterCtaWrapper>
  );
};
export default FooterCta;
