import {motion} from 'framer-motion';
import styled from 'styled-components';

import Button from '@/components/Button';
import Copy from '@/components/Copy';
import {COLOR_RED, COLOR_WHITE} from '@/theme/colors';
import {gridGutters} from '@/theme/grid';
import {pxToRem} from '@/theme/grid';
import {SPACING_XS} from '@/theme/spacings';
import {Z_INDEX_DEBUG} from '@/theme/zIndex';

export const PreviewWrapper = styled(motion.div)`
  position: fixed;
  top: 0;

  width: 100%;
  z-index: ${Z_INDEX_DEBUG};
  text-align: center;

  display: flex;
  align-items: center;
`;

export const StyledCopy = styled(Copy)`
  height: 100%;
  width: 100%;
  margin: 0;
  padding: ${SPACING_XS};
  color: ${COLOR_WHITE};
  background-color: ${COLOR_RED};
`;

export const StyledButton = styled(Button)`
  position: absolute;
  right: ${gridGutters(1)};

  width: ${pxToRem(30)};
  height: ${pxToRem(30)};
`;
