import {HTMLAttributes, useMemo} from 'react';

import {Text, Block, Inline} from '@contentful/rich-text-types';
import {useInView} from 'react-intersection-observer';

import TextReveal, {
  revealState,
  TextRevealGroup,
} from '@/components/TextReveal';
import {RichText} from '@/content/cms/types';
import {
  EASE_ON,
  EASE_OUT,
  TRANSITION_SPEED_RAPID,
  TRANSITION_SPEED_REGULAR,
  TRANSITION_SPEED_SLOW,
} from '@/theme/transitions';

import SkipInitialAnimatePresence from '../SkipInitialAnimatePresence';
import {StyledHeading, StyledUnderline} from './styles';

export const textReveal = (level: number, delay?: number) => ({
  initial: {y: '100%'},
  animate: {
    y: 0,
    transition: {
      ease: EASE_ON,
      duration: TRANSITION_SPEED_SLOW,
    },
  },
  exit: {
    y: '-100%',
    transition: {
      ease: EASE_OUT,
      duration: TRANSITION_SPEED_REGULAR,
    },
  },
  animateStagger: level < 3 ? 0.08 : 0.03,
  exitStagger: 0.02,
  animateDelay: delay,
});

export type Sizes = 1 | 2 | 2.5 | 3 | 4 | 5 | 6;

interface Props extends HTMLAttributes<HTMLHeadingElement> {
  className?: string;
  level?: Sizes;
  richText?: RichText;
  node?: Block | Inline;
  animate?: boolean;
  /**
   * Define if you want to control when heading reveals. Else, heading reveals
   * when in view by default.
   */
  revealState?: revealState;
  revealDelay?: number;
}

const Heading: React.FC<Props> = ({
  className,
  level = 1,
  animate = false,
  richText,
  node,
  revealState,
  revealDelay = TRANSITION_SPEED_RAPID,
  children,
  ...rest
}) => {
  const [inViewRef, inView] = useInView({triggerOnce: true, threshold: 0.1});

  const textRevealGroups: TextRevealGroup[] = useMemo(() => {
    if (typeof children === 'string' && !richText) {
      return [{text: children as string}];
    }

    if (typeof children !== 'string' && (richText || node)) {
      const elements: TextRevealGroup[] = [];

      // Get richText elements...
      if (richText) {
        const {content} = richText.json;

        content.forEach((block: Block) => {
          block.content.forEach((tag: Block | Inline | Text) => {
            const text = tag as Text;
            if (text.value !== '') {
              elements.push({
                text: text.value,
                innerTag: text.marks.length ? StyledUnderline : undefined,
              });
            }
          });
        });
      }

      // Get node elements...
      else if (node) {
        const {content} = node;
        content.forEach((tag: Block | Inline | Text) => {
          const text = tag as Text;
          if (text.value !== '') {
            elements.push({
              text: text.value,
              innerTag: text.marks.length ? StyledUnderline : undefined,
            });
          }
        });
      }

      return elements;
    }

    return [];
  }, [children, node, richText]);

  return (
    <StyledHeading
      as={`h${level === 2.5 ? 2 : level}`}
      ref={inViewRef}
      className={className}
      $level={level}
      $animated={animate}
      {...rest}
    >
      {animate ? (
        <SkipInitialAnimatePresence>
          <TextReveal
            content={textRevealGroups}
            reveal={level === 1 ? 'character' : 'word'}
            {...textReveal(level, revealDelay)}
            enabledMasking
            state={revealState ? revealState : inView ? 'animate' : 'initial'}
          />
        </SkipInitialAnimatePresence>
      ) : (
        textRevealGroups.map((element, index) => {
          const Tag = element.innerTag;
          const children = {children: element.text};
          return Tag ? <Tag key={index} {...children} /> : element.text;
        })
      )}
    </StyledHeading>
  );
};

export default Heading;
