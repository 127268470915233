import {useEffect, useState} from 'react';

import {useRouter} from 'next/router';

/**
 * Checks for 'previewToken' in the url parameters
 * if present fetches props again using the previewToken
 */
const useFetch = <T>(props: T, handleFetch: (token?: string) => Promise<T>) => {
  const router = useRouter();

  const [fetchProps, setFetchProps] = useState(props);

  useEffect(() => {
    const token = router.query.previewToken as string;
    if (token) {
      const handleSetProps = async () => {
        const props = await handleFetch(token);
        setFetchProps(props);
      };
      handleSetProps();
    }
  }, [handleFetch, router.query.previewToken]);

  return fetchProps;
};

export default useFetch;
