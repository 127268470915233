import {useEffect, useRef} from 'react';

import {useLocomotiveScroll} from 'react-locomotive-scroll';

import Socials from '@/components/Socials';
import {useLocomotiveElementConfig} from '@/config/locomotive';
import {eventClickFooterNavLink} from '@/config/tracking';
import {LinkWithHeading} from '@/content/cms/types';
import {useGlobals} from '@/contexts/globals';
import {useNav} from '@/contexts/nav';
import useClientMediaQuery from '@/hooks/useClientMediaQuery';
import {clamp} from '@/utils/math';

import FooterCta from './FooterCta';
import {
  StyledFooter,
  Content,
  ContentLeft,
  StyledLogo,
  ContentRight,
  StyledLink,
  Copyright,
  Shadow,
  ANIMATE_MEDIA_QUERY,
} from './styles';

export const FOOTER_ID = 'page-footer';

interface Props {
  cta?: LinkWithHeading;
}

const Footer = ({cta}: Props) => {
  const {footer} = useGlobals();
  const {subNavShowing, setSubNavShowing} = useNav();

  const {scroll} = useLocomotiveScroll();
  const locomotiveProps = useLocomotiveElementConfig({id: FOOTER_ID});
  const optionalLocomotiveProps = !!cta && locomotiveProps;
  const footerRef = useRef<HTMLDivElement>(null);
  const shadowRef = useRef<HTMLDivElement>(null);
  const animate = useClientMediaQuery(ANIMATE_MEDIA_QUERY);

  useEffect(() => {
    if (!scroll || !cta) return;

    scroll.on(
      'scroll',
      (args: {currentElements: {[x: string]: {progress: number}}}) => {
        if (
          typeof args.currentElements[FOOTER_ID] === 'object' &&
          shadowRef.current &&
          footerRef.current
        ) {
          if (subNavShowing) setSubNavShowing(false);
          const progress = args.currentElements[FOOTER_ID].progress;
          const clampedProgress = clamp(progress * 2, 0, 1);
          const invertedProgress = Math.abs(clampedProgress - 1);
          const parallax = `translateY(-${invertedProgress * 50}vh)`;

          footerRef.current.style.transform = animate ? parallax : '';
          shadowRef.current.style.opacity = animate
            ? `${invertedProgress}`
            : '';
        }
      },
    );
  }, [cta, scroll, animate, subNavShowing, setSubNavShowing]);

  return (
    <StyledFooter
      id={FOOTER_ID}
      ref={footerRef}
      $hasCta={!!cta}
      {...optionalLocomotiveProps}
    >
      {cta && <FooterCta cta={cta} />}
      <Content $hasCta={!!cta}>
        <ContentLeft>
          <StyledLogo theme="light" />
          <Socials socials={footer.socialsCollection.items} theme="light" />
        </ContentLeft>
        <ContentRight>
          {footer.linksCollection.items.map((link, index) => (
            <StyledLink
              key={index}
              link={link}
              tracking={eventClickFooterNavLink(link.title)}
              $index={index}
              $inExternalGroup={!!link.url}
            >
              {link.title}
            </StyledLink>
          ))}
        </ContentRight>
      </Content>
      <Copyright sansSize="small">{footer.copyright}</Copyright>
      {!!cta && <Shadow ref={shadowRef} />}
    </StyledFooter>
  );
};

export default Footer;
