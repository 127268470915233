import {pxToRem} from '@/theme/grid';
import {SVGRProps} from '@/utils/svgrProps';

import IconWrapper from '../IconWrapper';
import CloseSVG from './icon.svg';

const Close: React.FC<SVGRProps> = ({
  className,
  width = pxToRem(12),
  height = pxToRem(12),
}) => (
  <IconWrapper width={width} height={height} className={className}>
    <CloseSVG width="100%" height="100%" />
  </IconWrapper>
);

export default Close;
