import styled from 'styled-components';

import Copy from '@/components/Copy';
import Heading from '@/components/Heading';
import Link from '@/components/Link';
import {onFocusOutline} from '@/theme/borders';
import {COLOR_WHITE} from '@/theme/colors';
import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {SPACING_L, SPACING_M, SPACING_S} from '@/theme/spacings';
import {
  TYPE_SIZE_HEADER_1,
  TYPE_SIZE_HEADER_1_AND_A_HALF,
  TYPE_SIZE_HEADER_1_AND_A_HALF_BASELINE_DESKTOP,
  TYPE_SIZE_HEADER_1_AND_A_HALF_BASELINE_TABLET,
  TYPE_SIZE_HEADER_1_BASELINE_DESKTOP,
  TYPE_SIZE_HEADER_1_BASELINE_TABLET,
  TYPE_SIZE_LARGE_BASELINE_DESKTOP,
  TYPE_SIZE_LARGE_BASELINE_TABLET,
  TYPE_WEIGHT_BOLD,
} from '@/theme/type';

export const FooterCtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${SPACING_L} 0;
  margin: auto 0;
`;

export const StyledHeading = styled(Heading)`
  margin: 0 0 ${SPACING_M};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-bottom: ${SPACING_L};
  }
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: column;

  @media ${QUERY_GREATER_THAN_MOBILE} {
    flex-direction: row;
    align-items: flex-end;
    gap: ${gridGutters(1)};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    gap: ${gridGutters(2)};
  }
`;

interface Props {
  $titleOverflowsWrapper?: boolean;
}

export const StyledLink = styled(Link)<Props>`
  width: 100%;
  ${TYPE_WEIGHT_BOLD}
  margin-bottom: ${SPACING_S};
  overflow: hidden;

  ${({$titleOverflowsWrapper}) =>
    $titleOverflowsWrapper ? TYPE_SIZE_HEADER_1_AND_A_HALF : TYPE_SIZE_HEADER_1}

  ${onFocusOutline()}

  @media ${QUERY_GREATER_THAN_MOBILE} {
    margin-bottom: 0;
  }

  /* Remove default link styles */
  color: inherit;
  text-decoration: none;
`;

export const StyledCopy = styled(Copy)<Props>`
  flex: none;
  color: ${COLOR_WHITE};
  width: ${gridGutters(12)};

  @media ${QUERY_GREATER_THAN_MOBILE} {
    width: ${gridGutters(6)};

    margin-bottom: calc(
      ${({$titleOverflowsWrapper}) =>
          $titleOverflowsWrapper
            ? TYPE_SIZE_HEADER_1_AND_A_HALF_BASELINE_TABLET
            : TYPE_SIZE_HEADER_1_BASELINE_TABLET} -
        ${TYPE_SIZE_LARGE_BASELINE_TABLET}
    );
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    width: ${gridGutters(5)};
    margin-bottom: calc(
      ${({$titleOverflowsWrapper}) =>
          $titleOverflowsWrapper
            ? TYPE_SIZE_HEADER_1_AND_A_HALF_BASELINE_DESKTOP
            : TYPE_SIZE_HEADER_1_BASELINE_DESKTOP} -
        ${TYPE_SIZE_LARGE_BASELINE_DESKTOP}
    );
  }
`;
