import styled, {css} from 'styled-components';

import Copy from '@/components/Copy';
import Link from '@/components/Link';
import Logo from '@/components/Logo';
import {onFocusOutline} from '@/theme/borders';
import {
  COLOR_ALMOST_BLACK,
  COLOR_BLACK,
  COLOR_ORANGE,
  COLOR_WHITE,
  COLOR_WHITE_10,
  COLOR_WHITE_50,
} from '@/theme/colors';
import {gridGutters} from '@/theme/grid';
import {QUERY_GREATER_THAN_TABLET, USING_MOUSE} from '@/theme/mediaQueries';
import {PREVENT_CLICKS} from '@/theme/pointerEvents';
import {SPACING_M, SPACING_S, SPACING_L, SPACING_XL} from '@/theme/spacings';
import {
  TRANSITION_SPEED_FAST,
  TRANSITION_EASE_ON,
  TRANSITION_SPEED_REGULAR,
  TRANSITION_EASE_CUBIC,
} from '@/theme/transitions';
import {TYPE_SIZE_SANS_REGULAR, TYPE_WEIGHT_REGULAR} from '@/theme/type';

const MINIMUM_ASPECT_RATIO = '(max-aspect-ratio: 4/2)';
export const ANIMATE_MEDIA_QUERY = `${QUERY_GREATER_THAN_TABLET} and ${USING_MOUSE} and ${MINIMUM_ASPECT_RATIO}`;

interface Props {
  $hasCta: boolean;
}

export const StyledFooter = styled.footer<Props>`
  display: flex;
  flex-direction: column;
  background: ${COLOR_ALMOST_BLACK};
  color: ${COLOR_WHITE};
  padding: 0 ${gridGutters(1)};
  position: relative;

  /* Used to animate JobForm submission state change */
  transition: opacity ${TRANSITION_SPEED_REGULAR}s ${TRANSITION_EASE_CUBIC};

  @media ${ANIMATE_MEDIA_QUERY} {
    ${({$hasCta}) =>
      $hasCta &&
      css`
        min-height: 100vh;
        justify-content: flex-end;
      `}
  }
`;

export const Content = styled.div<Props>`
  padding: ${SPACING_L} 0;

  @media ${QUERY_GREATER_THAN_TABLET} {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    padding: ${SPACING_XL} 0;
  }

  ${({$hasCta}) =>
    $hasCta &&
    css`
      border-top: 1px solid ${COLOR_WHITE_10};
    `}
`;

export const ContentLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${QUERY_GREATER_THAN_TABLET} {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const StyledLogo = styled(Logo)`
  width: fit-content;

  ${onFocusOutline()}

  @media ${QUERY_GREATER_THAN_TABLET} {
    margin-bottom: ${SPACING_L};
  }
`;

export const ContentRight = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: 1fr;
  grid-column-gap: ${gridGutters(2)};
  grid-row-gap: ${SPACING_M};
  margin-top: ${SPACING_L};
  width: fit-content;

  /* Only show hover on links */
  > a {
    transition: opacity ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_CUBIC},
      color ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_ON};

    &:hover {
      color: ${COLOR_ORANGE};
    }
  }

  /* Links render as span when active */
  > span {
    opacity: 0.5;
    transition: opacity ${TRANSITION_SPEED_FAST}s ${TRANSITION_EASE_CUBIC};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    grid-template-columns: repeat(3, ${gridGutters(2)});
    height: fit-content;
    width: 100%;
    margin-top: 0;
  }
`;

interface StyledLinkProps {
  $index: number;
  $inExternalGroup: boolean;
}

export const StyledLink = styled(Link)<StyledLinkProps>`
  display: block;
  width: fit-content;
  height: fit-content;
  ${TYPE_SIZE_SANS_REGULAR}

  ${onFocusOutline()}

  /* Remove default link styles */
  color: inherit;
  text-decoration: none;

  grid-column: ${({$inExternalGroup}) => ($inExternalGroup ? 2 : 1)};
  grid-row: ${({$index}) => ($index % 7) + 1};

  @media ${QUERY_GREATER_THAN_TABLET} {
    grid-area: ${({$index}) =>
      $index < 4
        ? `${$index + 1} / 1`
        : $index < 7
        ? `${$index - 3} / 2`
        : `${$index - 6} / 3`};
  }
`;

export const Copyright = styled(Copy)`
  width: 100%;
  text-align: center;
  border-top: 1px solid ${COLOR_WHITE_10};
  padding: ${SPACING_S} 0;
  color: ${COLOR_WHITE_50};
  margin: 0;
  ${TYPE_WEIGHT_REGULAR}
`;

export const Shadow = styled.div`
  display: none;

  @media ${ANIMATE_MEDIA_QUERY} {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: block;
    background: linear-gradient(to bottom, ${COLOR_BLACK}, transparent);
    ${PREVENT_CLICKS}
  }
`;
