import {motion} from 'framer-motion';
import styled, {css} from 'styled-components';

import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {
  SPACING_XXL,
  SPACING_XL,
  SPACING_L,
  SPACING_M,
  SPACING_S,
} from '@/theme/spacings';
import {
  TYPE_SIZE_HEADER_1,
  TYPE_SIZE_HEADER_2,
  TYPE_SIZE_HEADER_2_AND_A_HALF,
  TYPE_SIZE_HEADER_3,
  TYPE_SIZE_HEADER_4,
  TYPE_SIZE_HEADER_5,
  TYPE_SIZE_HEADER_6,
} from '@/theme/type';
import {
  insetUnderline,
  UNDERLINE_H1,
  UNDERLINE_H2,
  UNDERLINE_H2_AND_A_HALF,
  UNDERLINE_H3,
  UNDERLINE_H4,
  UNDERLINE_H5,
  UNDERLINE_H6,
} from '@/theme/underlines';

import {Sizes} from '.';
import {INNER_TAG_BASE} from '../TextReveal/styles';

const HEADING_SIZES = {
  [1]: {
    type: TYPE_SIZE_HEADER_1,
    underline: UNDERLINE_H1,
    topMargin: {
      desktop: SPACING_XXL,
      tablet: SPACING_XL,
      mobile: SPACING_L,
    },
  },
  [2]: {
    type: TYPE_SIZE_HEADER_2,
    underline: UNDERLINE_H2,
    topMargin: {
      desktop: SPACING_XXL,
      tablet: SPACING_XL,
      mobile: SPACING_L,
    },
  },
  [2.5]: {
    type: TYPE_SIZE_HEADER_2_AND_A_HALF,
    underline: UNDERLINE_H2_AND_A_HALF,
    topMargin: {
      desktop: SPACING_XXL,
      tablet: SPACING_XL,
      mobile: SPACING_L,
    },
  },
  [3]: {
    type: TYPE_SIZE_HEADER_3,
    underline: UNDERLINE_H3,
    topMargin: {
      desktop: SPACING_XL,
      tablet: SPACING_XL,
      mobile: SPACING_L,
    },
  },
  [4]: {
    type: TYPE_SIZE_HEADER_4,
    underline: UNDERLINE_H4,
    topMargin: {
      desktop: SPACING_L,
      tablet: SPACING_M,
      mobile: SPACING_M,
    },
  },
  [5]: {
    type: TYPE_SIZE_HEADER_5,
    underline: UNDERLINE_H5,
    topMargin: {
      desktop: SPACING_M,
      tablet: SPACING_M,
      mobile: SPACING_M,
    },
  },
  [6]: {
    type: TYPE_SIZE_HEADER_6,
    underline: UNDERLINE_H6,
    topMargin: {
      desktop: SPACING_S,
      tablet: SPACING_S,
      mobile: SPACING_S,
    },
  },
};

interface Props {
  $level: Sizes;
  $animated?: boolean;
}

export const StyledHeading = styled.h1<Props>`
  ${({$level, $animated}) =>
    $level &&
    css`
      ${HEADING_SIZES[$level].type}
      margin: ${HEADING_SIZES[$level].topMargin.mobile} 0 0;

      @media ${QUERY_GREATER_THAN_MOBILE} {
        margin-top: ${HEADING_SIZES[$level].topMargin.tablet};
      }

      @media ${QUERY_GREATER_THAN_TABLET} {
        margin-top: ${HEADING_SIZES[$level].topMargin.desktop};
      }

      u {
        ${insetUnderline(HEADING_SIZES[$level].underline, !$animated)};
      }
    `};
`;

export const StyledUnderline = styled(motion.u)`
  text-decoration: none;
  ${INNER_TAG_BASE}
`;
