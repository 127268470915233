import {useCallback, useEffect, useRef} from 'react';

import {trackEvent} from '@phntms/react-gtm';
import {MetaHeadEmbed} from '@phntms/react-share';
import useKey from '@phntms/use-key';
import {motion} from 'framer-motion';
import Head from 'next/head';
import {useRouter} from 'next/router';
import {useLocomotiveScroll} from 'react-locomotive-scroll';

import {CursorType} from '@/components/Cursor';
import Footer from '@/components/Footer';
import {ScrollProperties} from '@/components/LocomotiveScroll';
import Preview from '@/components/Preview';
import {eventScroll} from '@/config/tracking';
import {Globals, Media, Page} from '@/content/cms/types';
import {useCursor} from '@/contexts/cursor';
import {GlobalsProvider} from '@/contexts/globals';
import {useNav} from '@/contexts/nav';
import useLocomotiveScrollDepth from '@/hooks/useLocomotiveScrollDepth';
import {GRID_OVERLAY_VISIBLE_CLASS} from '@/theme/grid';
import {
  TRANSITION_SPEED_REGULAR,
  EASE_OUT,
  EASE_CUBIC,
} from '@/theme/transitions';

import {Content} from './styles';

const SCROLL_DELTA = 5;

const FADE_IN_OUT_ANIMATION = {
  initial: {opacity: 0},
  animate: {
    opacity: 1,
    transition: {
      duration: TRANSITION_SPEED_REGULAR,
      ease: EASE_CUBIC,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: TRANSITION_SPEED_REGULAR,
      ease: EASE_OUT,
    },
  },
};

interface Props {
  globals: Globals;
  title?: string;
  headerLabel?: string;
  shareMedia?: Media;
  page?: Page;
  reduceDesktopTopPadding?: boolean;
  showFooter?: boolean;
}

const PageLayout: React.FC<Props> = ({
  globals,
  title,
  headerLabel,
  shareMedia,
  page,
  children,
  reduceDesktopTopPadding,
  showFooter = true,
}) => {
  const {subNavScrolling, setHeader, setLabel, setNavShowing, setAtTopOfPage} =
    useNav();
  const {setType} = useCursor();
  const previousScrollYRef = useRef(0);
  const currentScrollYRef = useRef(0);
  const {scroll} = useLocomotiveScroll();
  const router = useRouter();

  useEffect(() => {
    setHeader(globals.header);
    setLabel(headerLabel || page?.headerLabel);
  }, [globals, headerLabel, page?.headerLabel, setHeader, setLabel]);

  // On new page...
  useEffect(() => {
    // Reset nav
    setNavShowing(true);
    setAtTopOfPage(true);

    // Reset cursor
    setType(CursorType.None);
  }, [setNavShowing, setAtTopOfPage, setType]);

  // Header scroll events...
  const handleScroll = useCallback(
    ({scroll}: ScrollProperties) => {
      const withinPageTopBounds = scroll.y < 20;
      setAtTopOfPage(withinPageTopBounds);

      previousScrollYRef.current = currentScrollYRef.current;
      currentScrollYRef.current = scroll.y;

      if (withinPageTopBounds) {
        setNavShowing(true);
        return;
      }

      // If user only scrolled a small amount, ignore
      const scrollDistance = Math.abs(
        previousScrollYRef.current - currentScrollYRef.current,
      );
      if (scrollDistance <= SCROLL_DELTA || subNavScrolling.current) return;

      setNavShowing(previousScrollYRef.current >= currentScrollYRef.current);
    },
    [subNavScrolling, setAtTopOfPage, setNavShowing],
  );

  useEffect(() => {
    if (!scroll) return;
    scroll.on('scroll', handleScroll);
  }, [scroll, handleScroll]);

  useLocomotiveScrollDepth(({percentage, progress, length}) =>
    trackEvent({data: eventScroll(percentage, progress, length)}),
  );

  useKey('g', (pressed: boolean, event: KeyboardEvent) => {
    // If not dev, ignore
    if (process.env.NODE_ENV !== 'development') return;

    const root = document.querySelector('html');

    // Toggle grid overlay on 'Ctrl + G' key event
    if (pressed && event.ctrlKey && root) {
      root.classList.toggle(GRID_OVERLAY_VISIBLE_CLASS);
    }
  });

  return (
    <>
      <MetaHeadEmbed
        render={(meta: React.ReactNode) => <Head>{meta}</Head>}
        pageTitle={(title || page?.title) as string}
        siteTitle={globals.siteTitle}
        titleTemplate={globals.titleTemplate}
        description={globals.siteDescription}
        baseSiteUrl={globals.baseSiteUrl}
        // Strip first character to prevent double slashes
        pagePath={router.asPath.substring(1)}
        imageUrl={
          shareMedia?.url || page?.shareAsset?.url || globals.shareAsset.url
        }
        imageAlt={
          shareMedia?.title ||
          page?.shareAsset?.title ||
          globals.shareAsset.title
        }
        twitter={{
          cardSize: 'large',
          siteUsername: globals.siteTwitterUsername,
        }}
      />
      {globals.preview && <Preview />}
      <GlobalsProvider globals={globals}>
        <motion.div data-scroll-section {...FADE_IN_OUT_ANIMATION}>
          <Content $reduceDesktopTopPadding={reduceDesktopTopPadding}>
            {children}
          </Content>
          {showFooter && <Footer cta={page?.footerCta} />}
        </motion.div>
      </GlobalsProvider>
    </>
  );
};

export default PageLayout;
