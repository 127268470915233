export const clamp = (input: number, min: number, max: number) =>
  Math.min(Math.max(min, input), max);

export const rerange = (
  value: number,
  minInput: number,
  maxInput: number,
  minOutput = 0,
  maxOutput = 1,
) =>
  ((maxOutput - minOutput) * (value - minInput)) / (maxInput - minInput) +
  minOutput;

export const lerp = (x: number, y: number, t: number) => (1 - t) * x + t * y;
