import {AnimatePresence} from 'framer-motion';

import Close from '@/components/icons/Close';
import {EVENT_PREVIEW} from '@/config/tracking';
import useToggle from '@/hooks/useToggle';
import {TRANSITION_SPEED_FAST} from '@/theme/transitions';

import {StyledCopy, PreviewWrapper, StyledButton} from './styles';

const Preview = () => {
  const [display, toggleDisplay] = useToggle(true);
  return (
    <AnimatePresence>
      {display && (
        <PreviewWrapper
          initial={{y: '-100%'}}
          animate={{y: 0}}
          exit={{y: '-100%'}}
          transition={{duration: TRANSITION_SPEED_FAST}}
        >
          <StyledCopy sansSize="regular">You are in Preview Mode</StyledCopy>
          <StyledButton
            round
            small
            tracking={EVENT_PREVIEW}
            onClick={toggleDisplay}
          >
            <Close />
          </StyledButton>
        </PreviewWrapper>
      )}
    </AnimatePresence>
  );
};

export default Preview;
