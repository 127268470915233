import styled from 'styled-components';

import {
  LOGO_HEIGHT_DESKTOP,
  LOGO_HEIGHT_MOBILE,
} from '@/components/Logo/LargeLogo/styles';
import {COLOR_LATTE} from '@/theme/colors';
import {gridGutters} from '@/theme/grid';
import {
  QUERY_GREATER_THAN_MOBILE,
  QUERY_GREATER_THAN_TABLET,
} from '@/theme/mediaQueries';
import {SPACING_L, SPACING_M, SPACING_XL, SPACING_XXL} from '@/theme/spacings';
import {Z_INDEX_CONTENT_OVERLAY} from '@/theme/zIndex';

interface Props {
  $reduceDesktopTopPadding?: boolean;
}

export const Content = styled.div<Props>`
  position: relative;
  padding: calc(${LOGO_HEIGHT_MOBILE} + ${SPACING_M} + ${SPACING_XL})
    ${gridGutters(1)} ${SPACING_XL};
  background: ${COLOR_LATTE};
  z-index: ${Z_INDEX_CONTENT_OVERLAY};

  /* Remove top margin of first child */
  > *:first-child {
    margin-top: 0;
  }

  @media ${QUERY_GREATER_THAN_MOBILE} {
    padding-top: calc(
      ${LOGO_HEIGHT_DESKTOP} + ${SPACING_L} +
        ${({$reduceDesktopTopPadding}) =>
          $reduceDesktopTopPadding ? SPACING_L : SPACING_XL}
    );
    padding-bottom: ${SPACING_XL};
  }

  @media ${QUERY_GREATER_THAN_TABLET} {
    padding-bottom: ${SPACING_XXL};
  }
`;
